import { Color, Vector2 } from 'three';

import glslScale from '@resn/gozer-glsl/transform/scale.glsl';

export const uniforms = () => {
    return {
        u_dissolve: { value: 0 },
        u_dissolve_offset: { value: new Vector2() },
        u_color_dissolve: { value: new Color(0x6a33e7) },
        tNoiseDissolve: { value: null },
    };
};
export const shaderChunks = {
    uniforms: /* glsl */ `
        // uniform vec3 u_noise_color;
        uniform sampler2D tNoiseDissolve;
        uniform float u_dissolve;
        uniform vec3 u_color_dissolve;
        uniform vec2 u_dissolve_offset;
    `,
    funcs: /* glsl */ `
        ${glslScale}

        vec4 dissolve(sampler2D tNoise, vec2 st, float amnt, vec3 color, vec2 offsetNoise) {
            
            vec2 stM = scale(st, vec2(1.0, - 0.5));
            vec2 stN = scale(st, vec2(0.5)) + offsetNoise;
            
            vec4 res;
            float feather = 0.4;
            float mask = smoothstep(1.0 - feather, 1.0, stM.y + mix(-feather, 1.0, amnt));
            // float mask = smoothstep(1.0 - feather, 1.0, stM.y + amnt);
            
            float n = texture2D(tNoise, stN).r;
            float nSmoothed = smoothstep(0.0, 0.5, n);
            
            vec3 cRamp = vec3(saturate(mask + (nSmoothed * mask)));
            vec3 cBlend = color;

            res.rgb = mix(blendOverlay(cRamp, cBlend, 1.), vec3(0.6), smoothstep(0.6, 1., mask));
            res.a = length(res + mask);
            
            return res;
        }
    `,
};
