import { Vector3 } from 'three';

import glslDesaturate from '@/libs/lygia/color/desaturate.glsl';
import glslSnoise from '@/libs/lygia/generative/snoise.glsl';

export const uniforms = () => {
    return {
        u_rwThickness: { value: 20 },
        u_rwMovement: { value: 10 },
        u_rwWavelengths: { value: new Vector3() },
        u_rwNoiseStrength: { value: 1 },
        u_rwNoiseScale: { value: 1 },
        u_rwOffset: { value: new Vector3() },
    };
};
export const shaderChunks = {
    uniforms: /* glsl */ `
        uniform float u_rwThickness; // frequency
        uniform float u_rwMovement;
        uniform vec3 u_rwWavelengths; // color
        uniform float u_rwNoiseStrength;
        uniform float u_rwNoiseScale;
        uniform vec3 u_rwOffset;
    `,
    funcs: /* glsl */ `
        ${glslDesaturate}
        ${glslSnoise}
        
        vec3 attenuation(vec3 vWVDirection, vec3 vWNormal, vec3 wavelengths, float filmThickness, float movement, float noiseStrength, float noiseScale, vec3 offset) {
            vec3 st = vPosition.xyz / (noiseScale / 5.0);
            vec3 q = vec3(
                snoise(st),
                snoise(st + vec3(1.0)),
                snoise(st + vec3(1.0))
            );
            vec3 r = vec3(
                snoise(st + vec3(1.4, 1.3, 1.0) * q + vec3(1.7, 9.2, 1.0)),
                snoise(st + vec3(2.0, 1.2, 1.0) * q + vec3(8.3, 2.8, 1.0)),
                snoise(st * q)
            );
            float noise = snoise(st + r);
            return 0.5 + 0.5 * cos((((filmThickness + (noise * noiseStrength)) / (vec3(wavelengths.r * 1.0, wavelengths.g * 0.8, wavelengths.b * 0.6) + 1.0)) * dot(normalize(vWVDirection + (offset * -0.001)), normalize(vWNormal))) + movement);
        }
        
        float accumAlpha = 0.0;
        vec3 rainbow(vec3 vWVDirection, vec3 vWNormal, float filmThickness, float movement, vec3 wavelengths, float noiseStrength, float noiseScale, vec3 offset, bool isMask, float mask, float alpha, out float calpha) {
            vec3 res = clamp(attenuation(vWVDirection, vWNormal, wavelengths, filmThickness, movement, noiseStrength, noiseScale, offset), 0.0, 2.0);
            float rainbowContribution = clamp(res.r + res.g + res.b, 0.0, 1.0);
            float lalpha = alpha * rainbowContribution * mask;
            calpha = mix(lalpha / clamp(lalpha + accumAlpha, 0.00001, 1.0), lalpha, float(isMask));
            accumAlpha += (1.0 - accumAlpha) * lalpha * (1.0 - float(isMask));
            
            return res;    
        }
    `,
};
