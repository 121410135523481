import { Color, MeshPhysicalMaterial, ShaderLib, UniformsUtils } from 'three';

import { defaultHooks, materialEasyUniforms, modifySource } from '@resn/gozer-three';

import {
    shaderChunks as glslMatcap,
    uniforms as uniformsMatcap,
} from '../shaders/ShaderChunks/matcap';
import glslBlend from '@/libs/lygia/color/blend.glsl';
import glslSaturate from '@/libs/lygia/math/saturate.glsl';
import glslSum from '@/libs/lygia/math/sum.glsl';
import glslRotate from '@/libs/lygia/space/rotate.glsl';

class BoxMaterial extends MeshPhysicalMaterial {
    constructor(props, options = { isFoam: false }) {
        props = Object.assign(
            {
                type: 'BoxMaterial',
                fog: true,
            },
            props
        );
        super(props);

        this.defines = {
            PHYSICAL: '',
            STANDARD: '',
            USE_UV: true,
            USE_FOG: false,
            USE_DEBUG: false,
            USE_RGB: true,
            ...props.defines,
        };

        this.onBeforeCompile = (shader) => {
            shader.vertexShader = modifySource(vertexShader, defaultHooks.vertexHooks, {
                uniforms: /* glsl */ `varying vec3 vWorldPosition;`,
                postTransform: /* glsl */ `
                        vec4 worldPosition = vec4( transformed, 1.0 );
                        worldPosition = modelMatrix * worldPosition;
                        vWorldPosition = worldPosition.xyz;
                    `,
            });
            shader.fragmentShader = modifySource(
                fragmentShader,
                {
                    uniforms: 'insertbefore:#include <common>',
                    functions: 'insertafter:#include <clipping_planes_pars_fragment>',
                    preLights: 'insertbefore:#include <lights_physical_fragment>',
                    postFragColor: 'insertafter:#include <opaque_fragment>',
                    replaceFog: 'replace:#include <fog_fragment>',
                },
                {
                    uniforms: /* glsl */ `
                        uniform sampler2D tRGB;
                        uniform sampler2D tNoise;
                        uniform sampler2D tMatcap0;
                        uniform sampler2D tMatcapIrri;
                        uniform vec3 u_color;

                        varying vec3 vWorldPosition;

                        ${glslMatcap.uniforms}
                    `,
                    functions: /* glsl */ `
                        ${glslBlend}
                        ${glslRotate}
                        ${glslSum}
                        ${glslSaturate}

                        ${glslMatcap.funcs}
                    `,
                    preLights: options.isFoam
                        ? shaderChunks.preLightsIn
                        : shaderChunks.preLightsOut,
                    postFragColor: shaderChunks.postFragColor,
                    replaceFog: shaderChunks.replaceFog,
                }
            );
        };

        const uniforms = {
            ...uniformsMatcap(),
            ...{
                tRGB: { value: null },
                tNoise: { value: null },
                tMatcap0: { value: null },
                tMatcapIrri: { value: null },
            },
        };
        this.uniforms = UniformsUtils.merge([ShaderLib.physical.uniforms, uniforms, this.uniforms]);

        materialEasyUniforms(this, uniforms);
    }
}
export default BoxMaterial;

const vertexShader = ShaderLib.physical.vertexShader;
const fragmentShader = ShaderLib.physical.fragmentShader;

const shaderChunks = {
    preLightsOut: /* glsl */ `
        vec3 mvPosition = vViewPosition;
        mvPosition = rotate(mvPosition, u_matcapRotationWorld, vec3(0.0, 1.0, 0.0));            

        vec4 color = vec4(vec3(0.0), 1.0);

        // RGB channel to define materials
        vec3 cRGB = vec3(0.0);
        #ifdef USE_RGB
            cRGB = texture2D(tRGB, vUv).rgb;
        #endif

        // ― Matcap Base
        #ifdef USE_MATCAP_BASE
            color.rgb = matcap(tMatcap, normal, mvPosition, false, 1.0, 1.0, color.a, u_matcapRotation);
        #endif

        // ― Color Blend 
        #ifdef USE_COLOR
        float mC = 1.0;
        #ifdef USE_RGB
            mC = sum(cRGB);
        #endif
            color.rgb = blendColor(color.rgb, u_color, 1. - mC);
        #endif

        // ― Matcap Matte
        #ifdef USE_MATCAP_0
            float mMC0 = 1.0;
            #ifdef USE_RGB
                mMC0 = cRGB.g * 0.7;
            #endif
            vec3 cMatcap0 = matcap(tMatcap0, normal, mvPosition, false, 0., 1.0, color.a, u_matcapRotation);
        #endif

        // ― Matcap Irridescent
        #ifdef USE_MATCAP_IRRI
            float mMcI = 1.0;
            #ifdef USE_RGB
                mMcI = cRGB.r;
            #endif
            vec3 cMatcapIrri = matcap(tMatcapIrri, normal , mvPosition, false, 0.0, 1.0, color.a, u_matcapRotation * 0.5, 2.);
        #endif           
        
        #if defined(USE_RGB) && !defined(USE_MATCAP_0)
            roughnessFactor = saturate(roughnessFactor + (cRGB.g * 0.12));
        #endif
    `,
    preLightsIn: /* glsl */ `
        vec3 cNoise = vec3(0.);
        #ifdef USE_NOISE
            cNoise = texture2D(tNoise, vUv).rgb;
        #endif
        diffuseColor.rgb = mix(diffuseColor.rgb, vec3(0.), smoothstep(0.3, 0.8, cNoise.r));
        roughnessFactor = smoothstep(0.1, 0.4, roughnessFactor);
        metalnessFactor = smoothstep(0.2, 0.6, metalnessFactor);
    `,
    // ― postFragment
    postFragColor: /* glsl */ `
        #ifdef USE_MATCAP_0
            outgoingLight = mix(outgoingLight, cMatcap0, mMC0);
        #endif
        #ifdef USE_MATCAP_IRRI
            outgoingLight = blendScreen(outgoingLight, cMatcapIrri, mMcI);
        #endif
        #ifdef USE_LIGHTMAP
            outgoingLight.rgb = mix(outgoingLight.rgb, outgoingLight.rgb * lightMapTexel.rgb, lightMapIntensity);
        #endif
        #ifdef USE_ROUGHNESSMAP
        #endif
        gl_FragColor = vec4( outgoingLight, diffuseColor.a );

        #if defined(USE_DEBUG) && defined(USE_MATCAP_IRRI)
            gl_FragColor.rgb = blendScreen(color.rgb, cMatcapIrri, mMcI);
        #endif
    `,
    replaceFog: /* glsl */ `
        #ifdef USE_FOG
            float fogFactor = smoothstep(fogNear, fogFar, vWorldPosition.z);
            gl_FragColor.rgb *= fogFactor;
        #endif
    `,
};
