<template><slot /></template>

<script setup>
    import { useEventBus } from '@vueuse/core';
    import { LinearFilter, LinearMipmapLinearFilter, LinearSRGBColorSpace, Vector3 } from 'three';
    import { computed, onMounted, reactive, shallowRef, watch } from 'vue';

    import { LoaderEvent } from '@resn/gozer-loading';
    import { randomInt } from '@resn/gozer-math';
    import { usePane, useRafBool, useThreeObject } from '@resn/gozer-vue';
    import { useLoader } from '@resn/gozer-vue/loading';
    import gsap from '@resn/gsap';

    import { getAllMeshes } from '../sandbox/utils';
    import PackMaterial from './materials/PackMaterial';
    import { loadBitmapTexture } from '~/libs/three/loadBitmapTexture';
    import { useGlobalAssets } from '~/providers/GlobalAssets';

    const PALETTE_PACK_DISSOLVE = ['#c5b3d2', '#b676cf', '#0a44df'];

    const props = defineProps({
        animations: { type: Array },
        active: { type: Boolean, default: true },
        image: { default: null },
        color: { default: '#000000' },

        hasPane: { type: Boolean, default: true },
        hasAmbientMotion: { type: Boolean, default: true },
        hasCompressedTextures: { type: Boolean, default: true },
    });
    const propsReactive = reactive({
        opened: false,

        mcRotate: 0,
        mcScale: 6,
        mcRotateWorld: 0,

        color: props.color,
        colorDissolve:
            PALETTE_PACK_DISSOLVE[randomInt(0, PALETTE_PACK_DISSOLVE.length)] || '#ff00ff',
        dissolveOffset: { x: 0, y: 0 },

        speed: 0.8,
        motionAmb: 0,
        dissolve: 0,
        bumpScale: 3,
    });

    const vPosStart = new Vector3();
    const vPosAmbient = new Vector3();

    const vRotStart = new Vector3();
    const vRotAmbient = new Vector3();

    const active = computed(() => props.active && propsReactive.opened);

    const object = shallowRef(null);
    const { object: objectOuter, props: propsObject } = useThreeObject(null, {
        name: 'Pack',
        addToParent: false,
        props: { v: true },
    });

    const bus = useEventBus('pack');
    const globalAssets = useGlobalAssets();

    const init = ({ object: objectRef } = {}) => {
        object.value = objectRef;
        disposeMaterial(objectRef);

        if (objectOuter) {
            objectRef.parent.add(objectOuter);
            objectOuter.add(objectRef);
        } else {
            vPosStart.copy(objectRef.position);
            vRotStart.copy(objectRef.rotation);
        }

        if (globalAssets.assets) updateMaterial();
        else globalAssets.loader.once(LoaderEvent.LOAD_COMPLETE, updateMaterial);

        loader?.start();
    };

    const shader = new PackMaterial();
    // const shader = new MeshNormalMaterial();

    const disposeMaterial = (object) => {
        getAllMeshes(object).forEach((node) => {
            node.material.dispose();
        });
    };

    let readyCount = 0;
    let readyTriggered = false;
    const checkReady = () => {
        if (readyCount === 3 && !readyTriggered) {
            bus.emit('ready'); // Emit "ready" event once both textures and RGB are loaded
            bus.reset();
            readyTriggered = true;
        }
    };

    const updateMaterial = () => {
        getAllMeshes(object.value).forEach((node) => {
            node.renderOrder = node.userData['renderorder'] || 2;
            node.material = shader;
        });
        updateTextures({});
    };

    const updateTextures = ({ matcapPack, matcapPack1, matcapPack0, mapBump, mapEdges }) => {
        const { assets } = globalAssets;

        if (assets) {
            shader.tMatcapIrri = assets.matcapRevised6;
            shader.tNoiseDissolve = assets.pNoise;
        }

        if (matcapPack) shader.tMatcap = matcapPack;
        if (matcapPack0) shader.tMatcap0 = matcapPack0;
        if (matcapPack1) shader.tMatcap1 = matcapPack1;
        if (mapBump) {
            shader.bumpMap = mapBump;
            shader.bumpMap.channel = 1;
        }
        if (mapEdges) shader.tEdges = mapEdges;

        shader.defines.USE_MATCAP_BASE = shader.tMatcap !== null;
        shader.defines.USE_MATCAP_IRRI = shader.tMatcapIrri !== null;
        shader.defines.USE_MATCAP_0 = shader.tMatcap0 !== null;
        shader.defines.USE_MATCAP_1 = shader.tMatcap1 !== null;

        readyCount++;
        checkReady();
    };

    const setUniforms = (props) => {
        const { color, colorDissolve } = props;
        if (shader) {
            shader.u_color.set(color);
            shader.u_color_dissolve.set(colorDissolve);
            shader.u_dissolve = props.dissolve;

            shader.u_matcapRotationWorld = props.mcRotateWorld;
            shader.u_matcapScale = props.mcScale;

            shader.uniforms.fogNear.value = -20;
            shader.uniforms.fogFar.value = 0;

            shader.bumpScale = props.bumpScale;

            shader.defines.USE_COLOR = shader.u_color.getHex() !== 0x000000;
            shader.needsUpdate = true;
        }
    };
    watch(propsReactive, setUniforms, { immediate: true });

    const { hasCompressedTextures } = props;
    const loader = useLoader({
        ...{
            matcapPack: '/textures/pack/matcap-pack0-cycles@lg.webp#texture',
            matcapPack0: '/textures/box/map-box-matcap-rough@lg.webp#texture',
            matcapPack1: '/textures/pack/matcap-pack0-reflection@lg.webp#texture',
            mapBump: '/textures/pack/map-bump-pack.png#texture',
            mapEdges: '/textures/pack/map-edges-pack.png#texture',
        },
        ...(hasCompressedTextures
            ? {
                  matcapPack: '/textures/pack/matcap-pack0-cycles-updated-etc1s.ktx2',
                  matcapPack0: '/textures/box/map-box-matcap-rough-updated-etc1s.ktx2',
                  matcapPack1: '/textures/pack/matcap-pack0-reflection-etc1s.ktx2',
              }
            : {}),
    }).once(LoaderEvent.LOAD_COMPLETE, ({ data }) => {
        const noFlipYMap = ['mapBump', 'mapEdges'];

        for (const key in data) {
            const map = data[key];
            map.name = key;
            map.colorSpace = LinearSRGBColorSpace;
            map.flipY = noFlipYMap.includes(key) ? false : true;
            if (key === 'mapBump' && hasCompressedTextures) {
                map.generateMipmaps = true;
                map.minFilter = LinearMipmapLinearFilter;
                map.magFilter = LinearFilter;
            }
            map.needsUpdate = true;
        }
        updateTextures(data);
    });

    const setRGBImage = (url) => {
        if (!url) {
            console.warn('No image URL provided for RGB texture');
            return;
        }
        loadBitmapTexture(url, { flipY: true }).then((texture) => {
            shader.tRGB?.dispose();
            shader.tRGB = texture;

            readyCount++;
            checkReady();
        });
    };
    watch(() => props.image, setRGBImage);
    onMounted(() => {
        setRGBImage(props.image);
    });

    const setColor = (color) => (propsReactive.color = color);
    watch(() => props.color, setColor);

    useRafBool(active, ({ timestamp, delta }) => {
        const ts = timestamp / 1000;
        const { speed, motionAmb } = propsReactive;

        vPosAmbient.set(0, Math.sin(ts * speed * 0.2), 0).multiplyScalar(0.1 * motionAmb);
        vRotAmbient
            .set(
                Math.sin(ts * speed * 0.4) * -0.5,
                Math.sin(ts * speed * 0.3) * 0.1,
                Math.cos(ts * speed * 0.6) * 0.2
            )
            .multiplyScalar(0.25 * motionAmb);

        const vRot = vRotStart.clone().add(vRotAmbient);
        const vPos = vPosStart.clone().add(vPosAmbient);

        objectOuter.position.copy(vPos);
        objectOuter.rotation.setFromVector3(vRot);

        shader.u_matcapRotation += (delta / 1000) * propsReactive.speed;
    });

    let tlShow, tlOpen;

    const show = ({ delay = 0, useFog = false } = {}) => {
        tlShow?.kill();
        tlOpen?.kill();
        tlShow = gsap
            .timeline({
                delay,
                onStart: () => {
                    propsObject.v = true;
                    propsReactive.opened = true;
                },
            })
            .to(
                propsReactive,
                {
                    motionAmb: Math.max(+props.hasAmbientMotion, 0.001),
                    duration: 2,
                    ease: 'sine.out',
                },
                0
            );

        shader.defines.USE_FOG = useFog;
    };

    const open = ({ delay = 0 } = {}) => {
        tlShow?.kill();
        tlOpen?.kill();
        tlOpen = gsap
            .timeline({
                delay,
                onStart: () => {
                    const rndColor = PALETTE_PACK_DISSOLVE[randomInt(0, 2)];
                    propsReactive.colorDissolve = rndColor;
                },
                onComplete: () => (propsObject.v = false),
            })
            .to(propsReactive, { motionAmb: 0, duration: 1.5, ease: 'sine.out' }, 0)
            .to(propsReactive, { dissolve: 1, duration: 1.5, ease: 'power1.inOut' }, 0)
            .set(propsReactive, { motionAmb: 0 });
    };

    const reset = () => {
        propsObject.v = true;
        propsReactive.opened = false;

        propsReactive.motionAmb = 0;
        propsReactive.dissolve = 0;

        vRotAmbient.set(0, 0, 0);
        vPosAmbient.set(0, 0, 0);

        shader.u_matcapRotation = 0;
    };

    defineExpose({
        init,
        show,
        open,
        reset,
        exposedVectors: { vRotStart, vPosStart },
    });

    if (props.hasPane)
        usePane(
            [
                {
                    value: propsReactive,
                    options: {
                        onChange: (e) => {
                            if (e.target.key == 'mcRotate') shader.u_matcapRotation = e.value;
                            if (e.target.key == 'mcScale') shader.u_matcapScale = e.value;
                        },
                    },
                },
            ],
            {
                title: 'Pack',
                expanded: false,
            }
        );
</script>
