<template>
    <slot />
</template>

<script setup>
    import { useEventBus } from '@vueuse/core';
    import { LinearSRGBColorSpace, PointLight, Vector3 } from 'three';
    import { computed, onMounted, reactive, ref, shallowRef, watch } from 'vue';

    import { LoaderEvent } from '@resn/gozer-loading';
    import { noop } from '@resn/gozer-misc';
    import { usePane, useRafBool, useThreeObject } from '@resn/gozer-vue';
    import { useLoader } from '@resn/gozer-vue/loading';
    import gsap from '@resn/gsap';

    import { getAllMeshes } from '../sandbox/utils';
    import BoxMaterial from './materials/BoxMaterial';
    import { loadBitmapTexture } from '~/libs/three/loadBitmapTexture';
    import { useGlobalAssets } from '~/providers/GlobalAssets';

    const props = defineProps({
        active: { type: Boolean, default: true },
        image: { default: '/textures/box/map-rgb-box@lg.webp' },
        color: { default: '#131313' },

        hasPane: { type: Boolean, default: true },
        hasAmbientMotion: { type: Boolean, default: true },
        hasCompressedTextures: { type: Boolean, default: true },
    });
    const propsReactive = reactive({
        mcRotate: 0,
        mcRotateWorld: 0,

        color: props.color,

        roughnessOut: 0.96,
        metalnessOut: 0,
        reflectivityOut: 0.45,
        bumpScaleOut: 2,
        lightMapIntensity: 1,

        motionAmb: 1,
        speed: 2,
    });

    const active = computed(() => props.active);
    const visible = ref(true);

    const object = shallowRef(null);
    const { object: objectLights } = useThreeObject(null, { name: 'BoxLights' });

    const bus = useEventBus('box');
    const globalAssets = useGlobalAssets();

    const setupLights = () => {
        [
            { x: -2.5, y: 1, z: 0, intensity: 800, decay: 3 },
            { x: -3, y: 1.3, z: -2.2, intensity: 600.0, decay: 3 },
            { x: -2.4, y: 1, z: 3.5, intensity: 600.0, decay: 3 },
        ].forEach(({ x, y, z, intensity, distance, decay }) => {
            const light = new PointLight(0xffffff, intensity, distance, decay);
            light.position.set(x, y, z);
            objectLights.add(light);
        });
    };

    const init = ({ object: objectRef } = {}) => {
        object.value = objectRef;

        vPosStart.copy(object.value.position);
        vRotStart.copy(object.value.rotation);

        disposeMaterial(object.value);
        setupLights();

        if (globalAssets.assets) updateMaterial();
        else globalAssets.loader.once(LoaderEvent.LOAD_COMPLETE, updateMaterial);

        loader?.start();
    };

    const vPosStart = new Vector3();
    const vPosAmbient = new Vector3();

    const vRotStart = new Vector3();
    const vRotAmbient = new Vector3();

    const shaderOut = new BoxMaterial({
        reflectivity: 0.5,
    });
    const shaderFoam = new BoxMaterial(
        {
            color: '#0C0D0F',
            roughness: 0.9,
            metalness: 0,
            bumpScale: 2.2,
            reflectivity: 0,
        },
        { isFoam: true }
    );
    const shaders = [shaderFoam, shaderOut];

    const disposeMaterial = (object) => {
        getAllMeshes(object).forEach((node) => {
            node.material.visible = false;
            node.material.dispose();
        });
    };

    const updateMaterial = () => {
        getAllMeshes(object.value).forEach((node) => {
            node.renderOrder = node.userData['renderorder'] || 0;
            node.material = node.name.includes('foam') ? shaderFoam : shaderOut;
        });
        updateTextures({});
    };

    let readyCount = 0;
    let readyTriggered = false;
    const checkReady = () => {
        if (readyCount === 3 && !readyTriggered) {
            bus.emit('ready'); // Emit "ready" event once both textures and RGB are loaded
            bus.reset();
            readyTriggered = true;
        }
    };

    const updateTextures = ({
        mapRGB,
        mapBumpOut,
        mapMatcapMatte,
        mapNoiseIn,
        mapAOOut,
        mapLightOut,
        mapAOIn,
        mapLightIn,
    }) => {
        // ― OUT
        if (mapMatcapMatte) shaderOut.tMatcap0 = mapMatcapMatte;
        if (mapRGB) shaderOut.tRGB = mapRGB;
        if (mapAOOut) shaderOut.aoMap = mapAOOut;
        if (mapLightOut) shaderOut.lightMap = mapLightOut;
        if (mapBumpOut) shaderOut.bumpMap = mapBumpOut;
        if (mapBumpOut) shaderOut.roughnessMap = mapBumpOut;

        // ― IN
        if (mapNoiseIn) {
            shaderFoam.tNoise = mapNoiseIn;
            shaderFoam.roughnessMap = mapNoiseIn;
            shaderFoam.metallnessMap = mapNoiseIn;
            shaderFoam.bumpMap = mapNoiseIn;
        }
        if (mapLightIn) shaderFoam.lightMap = mapLightIn;
        if (mapAOIn) shaderFoam.aoMap = mapAOIn;

        const { assets } = globalAssets;

        if (assets) {
            shaderOut.tMatcapIrri = assets.matcapRevised6;
        }

        shaders.forEach((shader) => {
            shader.defines.USE_MATCAP_BASE = shader.tMatcap !== null;
            shader.defines.USE_MATCAP_0 = shader.tMatcap0 !== null;
            shader.defines.USE_MATCAP_IRRI = shader.tMatcapIrri !== null;
            shader.defines.USE_NOISE = shader.tNoise !== null;
        });

        readyCount++;
        checkReady();
    };

    useRafBool(active, ({ timestamp }) => {
        const ts = timestamp / 1000;
        const { speed, motionAmb } = propsReactive;
        const obj = object.value;

        if (obj) {
            vPosAmbient.set(0, Math.sin(ts * speed * 0.14), 0).multiplyScalar(0.2 * motionAmb);
            vRotAmbient
                .set(0, Math.sin(ts * speed * 0.3) * 0.2, Math.cos(ts * speed * -0.1) * 0.3)
                .multiplyScalar(0.4 * motionAmb);

            const vRot = vRotStart.clone().add(vRotAmbient);
            const vPos = vPosStart.clone().add(vPosAmbient);

            obj.position.copy(vPos);
            obj.rotation.setFromVector3(vRot);

            if (objectLights) objectLights.position.copy(vPos).multiplyScalar(-2);
        }
    });

    const setUniforms = (props_) => {
        // ― OUT
        shaderOut.roughness = props_.roughnessOut;
        shaderOut.metalness = props_.metalnessOut;
        shaderOut.reflectivity = props_.reflectivityOut;
        shaderOut.bumpScale = props_.bumpScaleOut;
        shaderOut.envMapIntensity = props_.envMapIntensity;
        shaderOut.lightMapIntensity = props_.lightMapIntensity;
        shaderOut.color.set(props_.color);
        shaderOut.fogColor = '#000000';

        // ― IN
        shaderFoam.envMapIntensity = 0.6;
        shaderFoam.aoMapIntensity = 1;
        shaderFoam.lightMapIntensity = props_.lightMapIntensity;

        shaders.forEach((shader) => {
            shader.u_matcapRotation = props_.mcRotate;
            shader.u_matcapRotationWorld = props_.mcRotateWorld;

            shader.uniforms.fogNear.value = -3;
            shader.uniforms.fogFar.value = -1;
        });
    };
    watch(propsReactive, setUniforms, { immediate: true });

    const { hasCompressedTextures } = props;
    const loader = useLoader({
        ...{
            mapMatcapMatte: '/textures/box/map-box-matcap-rough-updated@lg.webp#texture',
            mapBumpOut: '/textures/box/map-box-noise.png#texture',
            mapAOOut: '/textures/box/map-box-out-ao@lg.webp#texture',
            mapLightOut: '/textures/box/map-box-out-shadow@lg.webp#texture',

            mapNoiseIn: '/textures/box/map-box-foam-bump@lg.webp#texture',
            mapLightIn: '/textures/box/map-box-foam-shadow@lg.webp#texture',
            mapAOIn: '/textures/box/map-box-foam-ao@lg.webp#texture',
        },
        ...(hasCompressedTextures
            ? {
                  mapMatcapMatte: '/textures/box/map-box-matcap-rough-updated-etc1s.ktx2',
                  mapBumpOut: '/textures/box/map-box-noise-etc1s.ktx2',
                  mapAOOut: '/textures/box/map-box-out-ao-updated-etc1s.ktx2',
                  mapLightOut: '/textures/box/map-box-out-shadow-updated-etc1s.ktx2',

                  mapNoiseIn: '/textures/box/map-box-foam-bump-etc1s.ktx2',
                  mapLightIn: '/textures/box/map-box-foam-shadow-updated-etc1s.ktx2',
                  mapAOIn: '/textures/box/map-box-foam-ao-updated-etc1s.ktx2',
              }
            : {}),
    }).once(LoaderEvent.LOAD_COMPLETE, ({ data }) => {
        const noFlipYMap = ['mapMatcapMatte', 'mapLightIn', 'mapAOIn', 'mapAOOut', 'mapLightOut'];

        for (const key in data) {
            const map = data[key];
            map.name = key;
            map.colorSpace = LinearSRGBColorSpace;
            map.flipY = noFlipYMap.includes(key) ? false : true;
            map.needsUpdate = true;
        }
        updateTextures(data);
    });
    // const audio = useAudio();

    // const playBoxRevealSound = () => {
    //     audio.play('boxOpen');
    // };

    const setColor = (color) => (propsReactive.color = color);
    watch(() => props.color, setColor);

    const setRGBImage = (url) => {
        if (!url) {
            console.warn('No image URL provided for RGB texture');
            return;
        }
        loadBitmapTexture(url, { flipY: true }).then((texture) => {
            shaderOut.tRGB?.dispose();
            shaderOut.tRGB = texture;

            readyCount++;
            checkReady();
        });
    };
    watch(() => props.image, setRGBImage);
    onMounted(() => {
        setRGBImage(props.image);
    });

    watch(visible, (v) => {
        object.value.visible = v;
        objectLights.visible = v;
    });

    let tlOpen;
    const open = ({ cbComplete = noop } = {}) => {
        tlOpen?.kill();
        tlOpen = gsap
            .timeline({
                onComplete: cbComplete,
            })
            .fromTo(
                propsReactive,
                { lightMapIntensity: 1 },
                { lightMapIntensity: 0, duration: 1.5, ease: 'sine.out', delay: 1.2 },
                0.1
            )
            .fromTo(
                propsReactive,
                { motionAmb: 1 },
                { motionAmb: 0, duration: 1.5, ease: 'sine.out' },
                0
            )
            .add(() => (objectLights.visible = false));
    };

    const show = () => {
        objectLights.visible = true;
        propsReactive.motionAmb = +props.hasAmbientMotion;
        propsReactive.lightMapIntensity = 1;
    };

    const reset = () => {
        objectLights.visible = true;
    };

    defineExpose({
        init,
        show,
        open,
        reset,
        visible,
    });

    if (props.hasPane)
        usePane([{ value: propsReactive, options: { step: 0.01 } }], {
            title: 'Box',
            expanded: false,
        });
</script>
