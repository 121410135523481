import { AdditiveAnimationBlendMode, AnimationMixer, LoopOnce } from 'three';

const useThreeAnimations = () => {
    const animationsMap = [];

    let mixer;
    // ― animation
    const setupAnimations = ({ animations, object }) => {
        mixer = new AnimationMixer(object);
        const createAnimation = ({ clip, object }) => {
            // const mixer = new AnimationMixer(object);
            const duration = clip.duration;

            const action = mixer.clipAction(clip);
            action.setEffectiveWeight(0);
            action.loop = LoopOnce;
            action.clampWhenFinished = true;

            animationsMap.push({
                name: clip.name,
                clip,
                object,
                // mixer,
                action,
                duration,
                progress: 0,
                weight: 1,
            });
        };
        animations.forEach((clip) => createAnimation({ clip, object }));
    };

    const getAnimation = (id) => {
        return animationsMap.find((_) => _.name == id);
    };

    const getAnimations = () => {
        return animationsMap;
    };

    /*     const setWeightAction = (action, weight) => {
    action.enabled = weight > 0;
    action.setEffectiveWeight(weight);
}; */

    const updateAnimations = ({ delta, timestamp }) => {
        mixer?.update(delta / 1000);
        // animationsMap.forEach(({ mixer, duration, progress }) => {
        // const time = duration * progress * 0.999;
        // setWeightAction(action, weight);
        // mixer.setTime(time);
        // mixer.update(delta / 1000);
        // });
    };

    const getMixer = () => {
        return mixer;
    };

    return {
        setupAnimations,
        updateAnimations,
        getAnimation,
        getAnimations,
        getMixer,
    };
};

export default useThreeAnimations;
