import { Color, MeshStandardMaterial, ShaderLib, UniformsUtils } from 'three';

import { defaultHooks, materialEasyUniforms, modifySource } from '@resn/gozer-three';

import {
    shaderChunks as glslDissolve,
    uniforms as uniformsDissolve,
} from '../shaders/ShaderChunks/dissolve';
import {
    shaderChunks as glslMatcap,
    uniforms as uniformsMatcap,
} from '../shaders/ShaderChunks/matcap';
import {
    shaderChunks as glslRainbow,
    uniforms as uniformsRainbow,
} from '../shaders/ShaderChunks/rainbow';
import glslBlend from '@/libs/lygia/color/blend.glsl';
import glslLuma from '@/libs/lygia/color/luma.glsl';
import glslGamma2Linear from '@/libs/lygia/color/space/gamma2linear.glsl';
import glslAAstep from '@/libs/lygia/math/aastep.glsl';
import glslSaturate from '@/libs/lygia/math/saturate.glsl';
import glslSum from '@/libs/lygia/math/sum.glsl';
import glslRotate from '@/libs/lygia/space/rotate.glsl';

class PackMaterial extends MeshStandardMaterial {
    constructor(props) {
        props = Object.assign(
            {
                type: 'PackMaterial',
                transparent: true,
                fog: true,
            },
            props
        );
        super(props);

        this.defines = {
            STANDARD: '',
            USE_UV: true,
            USE_ALPHAHASH: true,
            USE_TRANSMISSION: true,
            USE_DISSOLVE: true,
            USE_COLOR: false,
            USE_FOG: true,
            USE_RGB: true,
            USE_DEBUG: false,
            ...props.defines,
        };
        this.onBeforeCompile = (shader) => {
            shader.vertexShader = modifySource(vertexShader, defaultHooks.vertexHooks, {
                uniforms: /* glsl */ `varying vec3 vTransformed;`,
                postTransform: /* glsl */ `vTransformed = transformed;`,
            });
            shader.fragmentShader = fragmentShader;
        };

        const uniforms = {
            ...uniformsMatcap(),
            ...uniformsRainbow(),
            ...uniformsDissolve(),

            ...{
                tMatcap0: { value: null },
                tMatcap1: { value: null },
                tMatcapIrri: { value: null },
                tEdges: { value: null },
                tRGB: { value: null },

                fogNear: { value: 0 },
                fogFar: { value: 3 },

                u_color: { value: new Color() },
            },
        };
        this.uniforms = UniformsUtils.merge([ShaderLib.standard.uniforms, uniforms, this.uniforms]);

        materialEasyUniforms(this, uniforms);
    }
}
export default PackMaterial;

const vertexShader = ShaderLib.standard.vertexShader;
const fragmentShader = /* glsl */ `
    #include <common>
    #include <normal_pars_fragment>
    #include <uv_pars_fragment>
    #include <bumpmap_pars_fragment>

    varying vec3 vTransformed;
    varying vec3 vViewPosition;
    varying vec3 vWorldPosition;

    uniform sampler2D tRGB;
    uniform sampler2D tMatcap0;
    uniform sampler2D tMatcap1;
    uniform sampler2D tMatcapIrri;
    uniform sampler2D tEdges;
    
    uniform vec3 u_color;
    
    #ifdef USE_FOG
    varying float vFogDepth;
    uniform float fogNear;
    uniform float fogFar;
    #endif
    
    ${glslBlend}
    ${glslRotate}
    ${glslLuma}
    ${glslSum}
    ${glslSaturate}
    ${glslAAstep}

    ${glslMatcap.uniforms}
    ${glslRainbow.uniforms}
    ${glslDissolve.uniforms}
        
    ${glslRainbow.funcs}
    ${glslMatcap.funcs}
    ${glslDissolve.funcs}
    
    ${glslGamma2Linear}
    
    void main() {
        #include <normal_fragment_begin>
        #include <normal_fragment_maps>
        
        vec3 viewPosition = vViewPosition;
        vec3 viewDir = normalize(viewPosition); // view direction in view space

        vec3 mvPosition = vViewPosition;
        mvPosition = rotate(mvPosition, u_matcapRotationWorld, vec3(0.0, 1.0, 0.0));
        
        vec4 color = vec4(vec3(0.0), 1.0);

        // RGB channel to define materials
        #ifdef USE_RGB
            vec3 cRGB = texture2D(tRGB, vUv).rgb;
        #endif

        // ― Matcap Base
        #ifdef USE_MATCAP_BASE
/*             vec2 st_mMCB_AA = scale(vTransformed.y * .5 + .5, 0.675);
            float mMCB_AA = aastep(0.02, 1. - st_mMCB_AA.y); */

            // float mMCB_AA = 1.;
            float mMCB_AA = 1. - aastep(0.1, texture2D(tEdges, vUv).r);

            color.rgb = matcap(tMatcap, normal, mvPosition, false, 1.0, 1.0, color.a, u_matcapRotation);
            color.rgb *= mMCB_AA;
        #endif

        // ― Matcap Matte
        #ifdef USE_MATCAP_0
            float mMC0 = 1.0;
            #ifdef USE_RGB
                mMC0 = cRGB.g;
            #endif
            vec3 cMatcap0 = matcap(tMatcap0, normal, mvPosition, false, 0., 1.0, color.a, u_matcapRotation);
            color.rgb = mix(color.rgb, cMatcap0, mMC0);
        #endif

        // ― Matcap Matte 1
        #ifdef USE_MATCAP_1
            float mMC1 = 1.0;
            #ifdef USE_RGB
                mMC1 = cRGB.b;
            #endif
            vec3 cMatcap1 = matcap(tMatcap1, normal, mvPosition, false, 0., 1.0, color.a, u_matcapRotation);
            color.rgb = mix(color.rgb, cMatcap1, mMC1);
        #endif

        // ― Rainbow 
        #ifdef USE_RAINBOW
            vec3 cRainbow = rainbow(viewDir, normal, u_rwThickness, u_rwMovement, u_rwWavelengths, u_rwNoiseStrength, u_rwNoiseScale, u_rwOffset, false, 1.0, 1.0, accumAlpha);
            float mRw = 1.0;
            #ifdef USE_RGB
                mRw = cRGB.r;
            #endif
            color.rgb = blendOverlay(color.rgb, cRainbow, mRw);
        #endif

        // ― Colorizing
        #ifdef USE_COLOR
        float mC = 1.0;
        #ifdef USE_RGB
            mC = cRGB.r * 0.3;
            // mC = sum(cRGB);
        #endif
        color.rgb = blendColor(color.rgb, u_color, 1. - mC);
        #endif

        // ― Noise Dissolve
        #ifdef USE_DISSOLVE
            vec2 stDissolve = scale(vTransformed.xy * .5 + .5, 1.5);
            vec4 resDissolve = dissolve(tNoiseDissolve, stDissolve, 1. - u_dissolve, u_color_dissolve, u_dissolve_offset);
            
            color.rgb = blendHardLight(color.rgb, resDissolve.rgb, smoothstep(0., 0.2, u_dissolve));
            color.a = resDissolve.a;
        #endif

        // ― Matcap Irridescent
        #ifdef USE_MATCAP_IRRI
            float mMcI = 1.0;
            #ifdef USE_RGB
                mMcI = cRGB.r;
            #endif
            vec3 cMatcapIrri = matcap(tMatcapIrri, normal, mvPosition, false, 0.0, 1.0, color.a, u_matcapRotation, u_matcapScale);
            color.rgb = blendColorDodge(color.rgb, cMatcapIrri, mMcI);
            // color.rgb = blendScreen(color.rgb, cMatcapIrri, mMcI);
        #endif

        gl_FragColor = color;

        #ifdef USE_FOG
            float fogFactor = smoothstep(fogNear, fogFar, -mvPosition.z + 20.);
            gl_FragColor.rgb *= fogFactor;
        #endif

        /* #if defined(USE_DEBUG) && defined(USE_BUMPMAP)
            gl_FragColor.rgb = vec3(bumpScale * texture2D( bumpMap, vBumpMapUv ).r);
        #endif */
/*         #if defined(USE_DEBUG) && defined(USE_DISSOLVE)
            vec2 ccc = scale(vTransformed.y * .5 + .5, 0.675);
            gl_FragColor.rgb = vec3(step(0.5, ccc.y));
        #endif */
        #if defined(USE_DEBUG) && defined(USE_MATCAP_BASE)
            gl_FragColor.rgb = vec3(1. - mMCB_AA);
        #endif
    }
`;
