import { HalfFloatType, LinearSRGBColorSpace, Mesh, NearestFilter, Object3D, Texture } from 'three';
import { EXRLoader } from 'three/examples/jsm/loaders/EXRLoader';

type resolveTexture = (value?: Texture) => void;

export const loadVATexrTexture = async (url: string) => {
    const loader = new EXRLoader();
    return new Promise((resolve: resolveTexture, reject) => {
        loader.setDataType(HalfFloatType).load(url, (texture: any) => {
            // texture as float value
            // texture.encoding = LinearEncoding;
            texture.colorSpace = LinearSRGBColorSpace;
            // get correct float value
            texture.magFilter = NearestFilter;
            // disable mipmap
            texture.generateMipmaps = false;

            resolve(texture);
        });
    });
};

// Utility function to get all meshes from an Object3D
export const getAllMeshes = (object: Object3D) => {
    const meshes: Mesh[] = [];
    object.traverse((child) => {
        if (child instanceof Mesh) meshes.push(child);
    });
    return meshes;
};

// Create a storage object to hold textures associated with their IDs
const textureStore: Record<string, Record<string, Texture>> = {};

// Function to retrieve all textures from a GLTF or Object3D model
export function storeTextures(object: Object3D): Record<string, Texture> | null {
    let acc = {};
    getAllMeshes(object).forEach((mesh) => {
        acc = { ...acc, ...getTexturesFromMaterial(mesh.material) };
    });
    return acc;
}
// Helper function to extract textures from a material and store them with their custom ID
export const getTexturesFromMaterial = (material: any) => {
    // Check if the material has a custom ID from Blender
    const textureID = material.userData?.textureID || material.name;

    // Extract only non-null textures
    const textures = {
        albedo: material.map || undefined,
        normal: material.normalMap || undefined,
        metalness: material.metalnessMap || undefined,
        roughness: material.roughnessMap || undefined,
        ao: material.aoMap || undefined,
        emissive: material.emissiveMap || undefined,
        displacement: material.displacementMap || undefined,
        alpha: material.alphaMap || undefined,
        transmission: material.transmissionMap || undefined,
        clearcoat: material.clearcoatMap || undefined,
        clearcoatNormal: material.clearcoatNormalMap || undefined,
        clearcoatRoughness: material.clearcoatRoughnessMap || undefined,
        sheenColor: material.sheenColorMap || undefined,
        sheenRoughness: material.sheenRoughnessMap || undefined,
        specular: material.specularMap || undefined,
        specularColor: material.specularColorMap || undefined,
        iridescence: material.iridescenceMap || undefined,
        iridescenceThickness: material.iridescenceThicknessMap || undefined,
        thickness: material.thicknessMap || undefined,
        lightMap: material.lightMap || undefined,
    };

    // Remove undefined values from the texture object
    const cleanedTextures = Object.fromEntries(
        Object.entries(textures).filter(([_, value]) => value !== undefined)
    );

    if (textureID) {
        // Store all texture maps under the unique ID
        textureStore[textureID] = cleanedTextures;
        return textureStore[textureID];
    } else {
        console.warn(`Material does not have a texture ID: ${textureID}`);
        return cleanedTextures;
    }
};

// Function to retrieve a texture by ID and map type
export const getTextureByID = (id: string, type: keyof (typeof textureStore)[typeof id]) => {
    if (textureStore[id] && textureStore[id][type]) {
        return textureStore[id][type] || null;
    } else {
        console.warn(`No texture "${type}" found for ID: ${id}`);
        return null;
    }
};

export const getTextureByName = (name: string) => {
    // look through all textures in the store and find the one with the matching name
    const values = Object.values(textureStore);
    const texture = values.reduce((acc, val) => {
        if (acc) return acc;
        return Object.values(val).find((tex) => tex?.name === name);
    }, null);
    if (!texture) console.warn(`No texture found with name: ${name}`);
    return texture;
};
